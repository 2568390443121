@import "_reset";
@import "_main_nav_and_header";
@import "_main_bloggers";
@import "_main_cooperation";
@import "_footer";
@import "_nav_and_header_pages";
@import "_about_us";
@import "_our_bloggers";
@import "_discover_poland";

/*---------------------------*/
/*--------GENERAL------------*/
/*---------------------------*/
body {
    background-color: white;
    font-family: 'Open Sans', sans-serif;


}

$red: rgb(208,0,0);
$grey: rgb(54,54,54);

.container {
    width: 1000px;
    margin: 0 auto;
    // border: 1px solid blue;
    display: flex;
    align-items: center;
    flex-direction: column;
    // @media ${tablet} {
    //
    // }
}

.row {
    display: flex;
    vertical-align: middle;
    justify-content: space-around;
    padding: 20px 0;
    width: 100%;
}

.col-1-2 {
    width: 50%;
    padding: 10px;
}

h3 {
    display: block;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
    color: white;
    font-size: 24px;
}

h4 {
    color: $grey;
    font-size: 40px;
    font-weight: bold;
    margin: 1em 1em 4px 4px;
    line-height: 1.5em;
    text-align: center !important;
    .dot {
        color: $red;
        font-size: 70px;
    }
}

.main-btn {
    text-decoration: none;
    display: inline-block;
    line-height: 2em;
    padding: 10px 20px;
    color: $red;
    font-weight: bold;
    background-color: white;
    font-size: 20px;
    border: 4px solid $red;
    border-radius: 50px;
    transition: 0.3s all;
}

.main-btn:hover {
    color: white;
    background-color: $red;
    // border: 4px solid white;
}

.main-btn-oncolor {
    text-decoration: none;
    display: inline-block;
    line-height: 2em;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    background-color: Transparent;
    font-size: 20px;
    border: 4px solid white;
    border-radius: 50px;
    transition: 0.3s all;
}

.main-btn-oncolor:hover {
    color: $red;
    background-color: white;
    // border: 4px solid white;
}


/*---------MAIN-ABOUT----------*/

.main-about {
    width: 100%;
    height: 300px;
    background-color: $red;
    .container {
        height: 100%;
        justify-content: center;
    }
}



/*--------MAIN-MAP---------*/
.main-map {
    width: 100%;
    height: 700px;
    .container {
        height: 100%;
    }
}

.map {
    width: 100%;
    // height: 500px;
    height: 100%;
    background: url(../images/world_map.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}





/*--------MOBILE---------*/

@media screen and (max-width: 999px) and (orientation: portrait)  {
    .container {
        width: 100%;
    }
}

@media screen and (max-width: 999px) and (orientation: landscape) {
    .container {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
    }
}


/*--------END---------*/
