$red: rgb(208,0,0);
$grey: rgb(54,54,54);

.discover-welcome,
.discover-game {
    padding: 20px 0;
    h4 {
        margin: 30px;
    }
    h3 {
        color: $grey;
        margin: 30px;
    }
}

#play-now-btn {
    margin: 20px 0;
}

.discover-game {
    display: none;
    // display: block
}
.discover-welcome {
    // display: none;
    display: block;
}

.random-city,
.your-score,
.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
}

.result {
    font-size: 24px;
    color: $red;
    font-weight: bold;
    margin: 6px;
}

#map {
    height: 500px;
    width: 60%;
    border: 4px solid $red;
}

.discover-game {
    .container {
        position: relative;
    }
    .row {
        position: relative;
    }
}

.answer {
    display: none;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 2em;
    padding: 20px;
    width: 60%;
    height: auto;
    background-color: rgba(255,255,255,0.8);
    border: 2px solid $red;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;
    span {
        color: $red;
        font-weight: bold;
    }
}

#next-round {
    display: block;
    margin: 10px;
}

#game-over {
    width: 80%;
    height: 500px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: $red;
    position: fixed;
    top: 10%;
    z-index: 4;

    h4 {
        color: white;
        span {
            display: inline-block;
            font-weight: bold;
        }
    }
    .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
            margin-left: 20px;
        }
    }

}


/* -- MEDIA QUERIES -- */


@media screen and (max-width: 768px) {
    .discover-game {
        .row {
            display: flex;
            flex-direction: column;
        }
    }

    #map {
        height: 400px;
        width: 100%;
        border: 4px solid $red;
    }
    .answer {
        width: 100%;
    }
    .btn-container {
        flex-direction: column;
    }

}
