$red: rgb(208,0,0);
$grey: rgb(54,54,54);

/*---------MAIN-HEADER & MAIN-NAV----------*/

.main-header {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(black, 0.2) 0%, rgba(black, 0.05) 100% ), url(../images/warsaw_starowka.jpg);
    // background-attachment: fixed; // parallax, przeniesiony do media queries
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .container {
        // margin-top: 10%;
    }
}

.main-header-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 0;
}

.main-nav {
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    padding: 10px;
    position: relative;
}

.main-nav-logo {
    z-index: 1;
}

.main-nav-menu {
    margin: 50px 0;
    z-index: 1;
    ul {
        text-align: right;
    }
    li {
        display: inline-block;
        transition: 1s all;
        // height: 3em;
        padding: 10px 0;
        a {
            text-decoration: none;
            font-size: 20px;
            color: white;
            padding: 0 20px;
            text-shadow: 1px 1px #000000;
        }
        &:hover {
            // text-decoration: underline;
            border-bottom: 2px solid white;
        }
    }
}

/* -- HAMBURGER -- */

.main-nav-menu-hamburger {
    display: none;
    align-self: baseline;
    margin-top: 20px;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    span {
        display: block;
        width: 50px;
        height: 8px;
        margin-bottom: 8px;
        background: white;
        border-radius: 3px;
    }
}

.main-nav-menu-hamburger-items {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    a {
        display: block;
        background-color: rgba(255,255,255,0.9);
        font-size: 20px;
        color: $red;
        line-height: 3em;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.7s, color 0.7s;
        &:hover {
            background-color: $red;
            color: white;
        }
        &:active {
            background-color: $red;
            color: white;
        }
    }

}

/* -- HELLO POLAND & H2 -- */

.main-header-title {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid blue;
    h1 {
        font-size: 80px;
        text-transform: uppercase;
        font-weight: bold;
        color: $red;
        text-shadow: 1px 1px #000000;
        height: 1.5em;
        display: block;
        #hello {
            font-size: 70px;
            font-weight: bold;
            color: white;
            text-align: center;
            width: 0px;
            height: 1.5em;
            white-space: nowrap;
            overflow: hidden;
        }
        #poland {
            font-weight: bold;
            font-size: 70px;
            text-align: center;
            width: 0px;
            height: 1.5em;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    h2 {
        display: block;
        width: 100%;
        font-size: 30px;
        color: white;
        text-shadow: 1px 1px #000000;
        text-align: center;
    }
}

#arrow-down {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: 0.7s all;
    img {
        z-index: 1;
        transition: 0.7s all;
    }
    &:hover {
        height: 70px;
        width: 70px;
        img {
            height: 70px;
        }
    }
}

/* -- MEDIA -- */

@media screen and (min-width: 1025px)  and (orientation: landscape) {
    .main-header {
        background-attachment: fixed;
    }
}

@media screen and (max-width: 999px) and (orientation: landscape) {
    .main-header {
        background-attachment: fixed;
        height: 100%;
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
    .main-header {
        background-attachment: local;
    }
    .main-header-title h1 #hello,
    .main-header-title h1 #poland {
        font-size: 50px;
        line-height: 1.5em;
    }
    .main-header-title h2 {
        font-size: 25px;
        margin-top: 1em;
    }
    .main-nav-menu {
        display: none;
    }
    .main-nav-menu-hamburger {
        display: block;
    }
    .main-nav-logo {
        width: 200px;
    }
}
