$red: rgb(208,0,0);
$grey: rgb(54,54,54);

.about-us {
    width: 100%;
    margin: 20px 0;
    h3 {
        color: $grey;
    }
}
