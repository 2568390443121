$red: rgb(208,0,0);
$grey: rgb(54,54,54);

.our-bloggers-search {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .row {
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
    }
    h3 {
        color: $grey;
        margin-top: 0;
        display: block;
        margin-bottom: 20px;
    }
}

.search-description {
    height: auto;
}

.search-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.sbtn {
    display: inline-block;
    margin: 5px;
    padding: 7px 10px;
    min-width: 120px;
    color: $grey;
    background-color: white;
    font-size: 16px;
    border: 4px solid $red;
    border-radius: 50px;
    text-align: center;
}

.sbtn-selected {
    background-color: $red;
    color: white;
}
.our-bloggers {
    .blog {
        // display: none;
        .show-blog {
            display: block;
        }
    }
}

/* -- MEDIA QUERIES -- */

@media screen and (max-width: 768px) {

}
