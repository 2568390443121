$red: rgb(208,0,0);
$grey: rgb(54,54,54);

/*---------PAGES HEADER & NAV----------*/

.pages-header {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(black, 0.2) 0%, rgba(black, 0.05) 100% );
    background-color: #81a1fc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
